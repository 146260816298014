
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #333;
}

.cards, .cards1, .cards2, .cards3{
    display: flex;
    justify-content: space-between;
}

.cards_last{
  display: flex;
  justify-content: center;
}

.container {
  max-width: 900px;
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
}

.card-wrapper {
  width: 350px;
  height: 450px;
  position: relative;
}

.card {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 400px;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  transition: 0.5s;
  
.card-image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: #000000;
    transition: .5s;
}

.card-image_photo{
  height: 650px;
}
  
&:hover img {
    opacity: 0.25;
    transition: .5s;
  }
}

.card:hover .card-image {
  transform: translateY(-100px);
  transition: all .9s;
}

/**** Social Icons *****/

.social-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  display: flex;
  
  li {
    list-style: none;
    
    a {
      position: relative;
      display: block;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background: #fff;
      font-size: 23px;
      color: #333;
      font-weight: bold;
      margin: 0 6px;
      transition: .4s;
      transform: translateY(200px);
      opacity: 0;
    } 
  }
}

.card:hover .social-icons li a {
  transform: translateY(0px);
  opacity: 1; 
}

.social-icons li a:hover {
  background: #000; 
  transition: .2s;
  .fab {
    color: #fff;
  } 
}

.social-icons li a .fab {
  transition: .8s;
    
  &:hover {
      transform: rotateY(360deg);
      color: #fff;
  } 
}

.card:hover li:nth-child(1) a {
    transition-delay: 0.1s;
}
.card:hover li:nth-child(2) a {
  transition-delay: 0.2s;
}
.card:hover li:nth-child(3) a {
  transition-delay: 0.3s;
}
.card:hover li:nth-child(4) a {
  transition-delay: 0.4s;
}

/**** Personal Details ****/

.details {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #000000;
  width: 100%;
  height: 120px;
  z-index: 1;
  padding: 10px;

 h2 {
    margin: 30px 0;
    padding: 0;
    text-align: center;
    background: none;
    font-weight: 800;
     
    .description {
        font-size: 1.5rem;
        line-height: 2.5rem;
        color: #fff;
        background: none;
        font-weight: 800;
    }
  }

}

.jane {
  position: absolute;
  bottom: -120px;
  left: 0;
  opacity: 0;
  background: #fff;
  width: 100%;
  height: 120px;
  z-index: 3;
  padding: 10px;
  transition: .4s;
}

.profile-two .social-icons li a {
  border-radius: 50%;
}

.card:hover .profile-img--two {
  transform: rotateY(180deg);
}

.card:hover .jane {
  bottom: 0;
  left: 0;
  transition-delay: 0.5s;
  opacity: 1;
}

@media screen and (min-width: 441px) and (max-width: 900px) {
    .card{
        width: 80%;
        height: 80%;
        
    }
    .card-wrapper{
        height: 200px;
    }
    .card-image_photo{
      height: 382px;
    }
    .details{
        h2{
            font-size: 100%;
            .description{
                font-size: 65%;
                line-height: 0.3rem;
            }
        }
    }
}


.mini_card {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 300px;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  transition: 0.5s;
  
.mini_card-image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: #000000;
    transition: .5s;
}

.mini_card-image_photo{
  height: 440px;
}
  
&:hover img {
    opacity: 0.25;
    transition: .5s;
  }
}

.mini_card_last {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 300px;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  transition: 0.5s;
  
.mini_card-image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: #000000;
    transition: .5s;
}

.mini_card-image_photo_last{
  height: 440px;
}
  
&:hover img {
    opacity: 0.25;
    transition: .5s;
  }
}

.mini_card-wrapper {
  width: 350px;
  height: 325px;
  position: relative;
}

@media screen and (max-width: 440px) {

  .card {
    width: 80%;
    height: 80%;
    .card-image_photo{
      height: 165px;
    }
  }

  &:hover img {
    /* opacity: 0.25; */
    transition: .5s;
  }

  .card:hover .card-image {
    transform: translateY(0px);
    transform: opacityTransform 0.4s ease-in;
    transition: all .9s;
  }

  @keyframes opacityTransform {
    0% {
      opacity: 0.5;
    }
  }

  /* .card:hover .details {
    transform: translateY(0px);
    transition: all .9s;
  } */

  .card-wrapper {
    height: 150px;
  }

  .mini_card {
    width: 80%;
    height: 80%;
    .mini_card-image_photo{
      height: 95px;
    }
  }

  .mini_card_last {
    width: 44%;
    height: 80%;
    .mini_card-image_photo_last{
      height: 105px;
    }
  }

  .mini_card-wrapper {
    height: 100px;
  }

  .details {
    h2 {
      font-size: 100%;

      .description {
        font-size: 65%;
        line-height: 0.3rem;
      }
    }
  }

}


