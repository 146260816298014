*, *::after, *::before{
    margin: 0;
    box-sizing: border-box;
}
html{
    font-size: 62.5%;
}
body{
    font-size: 1.6rem;
}
a{
    text-decoration: none;
}

.nav_logo{
    width: 216px;
    background: none;
    -webkit-filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.75));
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.75));
}

.links{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 25px !important;
    font-size: 95%;
    font-weight: 600;
    /* margin-left: 0; */
    height: 2vh;
    margin-right: 20px;
}

.menu_toggle{
    width: 40px;
}

.close_toggle{
    width: 40px;
}

.toggle_navbar{
    display: flex;
    align-items: center;
    margin-left: auto; /* JOBBRA HELYEZI!!! */
    margin-right: 5rem;
    cursor: pointer;
    display: none;
}

.close_navbar{
    display: flex;
    align-items: center;
    margin-left: auto; /* JOBBRA HELYEZI!!! */
    margin-right: 5rem;
    cursor: pointer;
    display: none;
}

.title_video{
    object-fit: cover;
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
}

@media screen and (min-width: 441px) and (max-width: 900px) {
    .toggle_navbar{
        display: block;
    }
    .close_navbar{
        display: none;
    }
    .links{
        position: fixed;
        z-index: 80;
        top: 12vh;
        right: 0;
        width: 40%;
        height: 93vh;
        background: #000000;
        transform: translateX(100%);
        transition: 0.5s ease-in;
        display: flex;
        flex-direction: column;
        margin-right: 0px;
    }
    .video_content{
        width: 80vw;
    }

    .icons{
        display:grid;
    }

    .socials{
        display: flex;
        justify-content: center;
    }

    .insta{
        margin-bottom: 10px;
    }

    .languages{
        display: flex;
        justify-content: center;
    }

    .language{
        width: 30px;
        height: 25px;
    }

}

@media screen and (min-width: 310px) and (max-width: 440px) {

    .toggle_navbar{
        display: block;
    }
    .close_navbar{
        display: none;
    }

    .nav_logo{
        width: 192px;
    }

    .menu_toggle{
        width: 30px;
    }

    .close_toggle{
        width: 30px;
    }

    .links{
        position: fixed;
        z-index: 80;
        top: 15vh;
        right: 0;
        width: 43%;
        height: 93vh;
        background: #000000;
        transform: translateX(100%);
        transition: 0.5s ease-in;
        display: flex;
        flex-direction: column;
        margin-right: 0px;
    }
    .video_content{
        width: 80vw;
    }

    .icons{
        display:grid;
    }

    .socials{
        display: flex;
        justify-content: center;
    }

    .insta{
        margin-bottom: 10px;
    }

    .languages{
        display: flex;
        justify-content: center;
    }

    .language{
        width: 30px;
        height: 25px;
    }

}

.active{
    transform: translateX(0);
}