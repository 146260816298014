@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  font-family: "Quicksand";
  color: #a07405;
  background-color: #171717;
  z-index: 50;
}
.navBar {
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 0;
  background-color: #000000;
  position: fixed;
  top: 0%;
  width: 100vw;
  z-index: 150;
}
.navBar h1 {
  color: #f1356d;
  background-color: #000000;
}
.navBar .links {
  margin-left: auto;
  background-color: #000000;
  color: #a07405;
}
.navBar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
  background-color: #000000;
}
.navBar img{
  justify-content: left;
}
.navBar a:hover {
  color: #ffffff;
}
.content {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
}

.home p{
  font-size: 220px;
}
.home h3{
  color:antiquewhite;
}

.carousel{
  width: 300px;
  display: inline;
  /* justify-content: center; */
  z-index: 150;
  /* margin: auto; */
}

.box{
  background-color: #171717;
  max-width: 1360px;
  margin: 0 auto;
  border-radius: 5px;
  padding: 10px 0px 0px;
  margin-top: 50px;
  z-index: 50;
  position: relative;
  text-align: justify;
}

.text{
  max-width: 1160px;
  margin: 0 auto;
}

.overlay{
  background-color: rgba(0, 0, 0, 1);
  position: fixed;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  z-index: 9999;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
}

.closeBtn{
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 10px; */
  margin-left: 970px;
  background-color: #a07405;
  color: #171717;
  width: 20px;
  cursor: pointer;
}

.modalContainer {
  max-width: 1000px;
  width: 100%;
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: slide-up 0.4s ease-out forwards;
  /* display: flex; */
  /* justify-content: center; */
  /* background-color: #ffffff; */
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
}

@keyframes slide-up {
  from {
    transform: translate(-50%, 0%);
  }
  to {
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 900px) {
  .modalContainer {
    max-width: 700px;
  }
}

/* .carousel{
  display: flex;
  justify-content: center;
} */

.welcome_video{
  object-fit: cover;
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 3;
  background: none;
  animation: slide-in 3.2s ease-in;
}

@keyframes slide-in {
  0% {
    opacity: 0;
  }
}

.title_box{
  background-color: #171717;
  max-width: 1660px;
  margin: 0 auto;
  border-radius: 5px;
  padding: 0.1px 0.1px 0.1px;
  margin-top: 50px;
  z-index: 2;
  position: relative;
}

.title{
  animation: slide-in 1.2s ease-in;
}

.title_text{
  color: #e7af21d6;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.75);
}

.title_logo{
  animation: slide-up 1.2s ease-in;
  opacity: 1;
  position: relative;
  width: 500px;
  background: none;
  -webkit-filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.75));
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.75));
}

@keyframes slide-in {
  0% {
    transform: translateY(-100px);
    transition: all .9s;
    opacity: 0;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100px);
    transition: all .9s;
    opacity: 0;
  }
}

/* VIDEO CAROUSEL */

.video_carousel {
  width: 1360px;
  height: 280px;
  margin: auto;
  display: inline;
  /* left: 20%; */
  align-items: center;
  position: relative;
  z-index: 150;
  transform: translate(-50%, -5%);
  max-width: 1360px;
  object-fit: cover;
}

.carousel_video{
  opacity: 0.4;
}

.caption {
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  position: absolute;
  bottom: 370px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  background: none;
  color: #e7af21;
  text-shadow: 3px 3px 0px rgba(0, 0, 0, 0.75);
  padding: 10px;
  font-size: 60px;
  font-style: bold;
  text-align: center;
  -webkit-transition: opacity .35s ease-in-out;
  -moz-transition: opacity .35s ease-in-out;
  -ms-transition: opacity .35s ease-in-out;
  -o-transition: opacity .35s ease-in-out;
  transition: opacity .35s ease-in-out;
}

.caption_lower {
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  position: absolute;
  bottom: 320px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  background: none;
  color: #ffffff;
  text-shadow: 1.5px 1.5px 0px rgba(0, 0, 0, 0.75);
  padding: 10px;
  font-size: 60px;
  font-size: 160%;
  font-style: bold;
  text-align: center;
  -webkit-transition: opacity .35s ease-in-out;
  -moz-transition: opacity .35s ease-in-out;
  -ms-transition: opacity .35s ease-in-out;
  -o-transition: opacity .35s ease-in-out;
  transition: opacity .35s ease-in-out;
}

.video_carousel:hover .slide .caption {
  opacity: 1;
}

.home_carousel {
  margin: 40px auto;
  padding: 20px;
  max-width: 750px;
  top: 58%;
  left: 50%;
  width: 80%;
  display: inline;
}

.photos{
  margin-top: 60px;
  margin-bottom: 60px;
}



.map_info{
  display: flex;
  align-items: center;
}

.map_text_title{
  margin-bottom: 100px;
}

.map_text{
  margin-left: 100px;
  text-align: left;
  position: relative;
}

.map_text_bottom{
  margin-bottom: 100px;
}

.map{
  display: flex;
  position: relative;
  margin-left: 290px;
  margin-top: 150px;
  width: 650px; 
  height: 400px;
  /* left: 65%; */
}

.map_luxor{
  display: flex;
  position: relative;
  margin-left: 265px;
  margin-top: 150px;
  width: 650px; 
  height: 400px;
  /* left: 65%; */
}


.prosoape_photos{
  display: flex;
  justify-content: center;
}

.mini_modalContainer {
  max-width: 1850px;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 18%;
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  animation: slide-up 0.4s ease-out forwards;
  /* background-color: #ffffff; */
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.75);
  /* opacity: 0.6; */
}

.mini_closeBtn{
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 10px; */
  margin-left: 805px;
  background-color: #a07405;
  color: #171717;
  width: 20px;
  cursor: pointer;
}

.deco_photo{
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 10px; */
  margin-left: 525px;
}

.carousel.carousel-slider .control-arrow{
  position: relative !important;
  z-index: 999 !important;
}

.carousel.carousel-slider{
  display: flex !important;
}

.home_photos{
  width: 900px;
}

.carousel_video{
  width: 1360px;
}

.footer{
  margin-top: 50px;
}

.about_header{
  font-size: 45px;
}

.break{
  color: "white";
  background: "none";
  height: 1px;
  width: 950px;
  display: flex;
  margin-left: 205px;
  margin-top: 100px;
  margin-bottom: 80px;
}

/* SCALE TO MOBILE */

@media screen and (max-width: 440px) {

  .box{
    background-color: #171717;
    max-width: 320px;
    margin: 0 auto;
    border-radius: 5px;
    padding: 10px 0px 0px;
    margin-top: 50px;
    z-index: 50;
    position: relative;
    text-align: justify;
  }

  .title_logo{
    width: 300px;
  }

  .home_photos{
    width: 300px;
  }

  h3{
    font-size: 14px;
    margin-left: 28px;
    margin-right: 28px;
  }

  .carousel_video{
    width: 1000px;
  }

  .caption{
    font-size: 25px;
    bottom: 270px;
  }

  .caption_lower{
    font-size: 12px;
    bottom: 250px;
  }

  .footer{
    margin-top: 30px;
  }

  h5{
    font-size: 10px;
  }

  /* about us */

  .about_header{
    font-size: 25px;
  }

  .break{
    width: 265px;
    margin-left: 25px;
    margin-top: 100px;
    margin-bottom: 80px;
  }

  .map_info{
    display: grid;
    align-items: center;
  }

  .map{
    margin-left: 0px;
    width: 300px; 
    height: 200px;
    margin-top: 50px;
    margin-left: 10px;
  }

  .map_luxor{
    margin-left: 0px;
    width: 300px; 
    height: 200px;
    margin-top: 50px;
    margin-left: 10px;
  }

  .map_text_title{
    margin-left: 25px;
    margin-bottom: 40px;
  }

  .map_text{
    margin-left: 0px;
    text-align: left;
    position: relative;
  }

  .map_text_bottom{
    margin-bottom: 0px;
  }

  /* products themes */

  .closeBtn{
    margin-left: 378px;
  }

  /* decos */

  .mini_modalContainer {
    max-width: 500px;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 5%;
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    animation: slide-up 0.4s ease-out forwards;
    /* background-color: #ffffff; */
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.75);
    /* opacity: 0.6; */
  }

  .deco_photo{
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-right: 10px; */
    margin-left: 35px;
  }

  .mini_closeBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-right: 10px; */
    margin-top: 90px;
    margin-left: 265px;
    background-color: #a07405;
    color: #171717;
    width: 20px;
    cursor: pointer;
  }

}